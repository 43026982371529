/* Testimonials.css */
.testimonials-section {
    padding: 6rem 1rem;
    background: #f8fafc;
}

.testimonials-container {
    max-width: 1200px;
    margin: 0 auto;
}

.section-header {
    text-align: center;
    margin-bottom: 4rem;
}

.section-header h2 {
    font-size: 2.5rem;
    color: #1e293b;
    margin-bottom: 1rem;
}

.subtitle {
    color: #3954ff;
    font-size: 1.1rem;
}

.testimonials-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-bottom: 4rem;
}

.testimonial-card {
    background: white;
    padding: 2rem;
    border-radius: 1.5rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease;
}

.testimonial-card:hover {
    transform: translateY(-5px);
}

.rating {
    margin-bottom: 1rem;
}

.star {
    color: #e2e8f0;
    font-size: 1.2rem;
}

.star.filled {
    color: #f59e0b;
}

.testimonial-text {
    color: #64748b;
    line-height: 1.6;
    margin-bottom: 1.5rem;
    font-size: 1rem;
}

.author-info {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.author-details {
    flex: 1;
}

.author-name {
    color: #1e293b;
    margin-bottom: 0.25rem;
    font-size: 1rem;
}

.author-role {
    color: #64748b;
    font-size: 0.875rem;
}

.social-proof {
    text-align: center;
    padding: 3rem 0;
}

.trusted-by {
    color: #64748b;
    margin-bottom: 1.5rem;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.company-logos {
    display: flex;
    justify-content: center;
    gap: 3rem;
    flex-wrap: wrap;
    align-items: center;
}

.company-logo {
    height: 40px;
    opacity: 0.7;
    transition: opacity 0.3s ease;
}

.company-logo:hover {
    opacity: 1;
}

@media (max-width: 768px) {
    .testimonials-section {
        padding: 4rem 1rem;
    }

    .section-header h2 {
        font-size: 2rem;
    }

    .testimonials-grid {
        grid-template-columns: 1fr;
    }

    .company-logos {
        gap: 2rem;
    }
}

@media (max-width: 480px) {
    .testimonial-card {
        padding: 1.5rem;
    }

    .company-logo {
        height: 30px;
    }
}