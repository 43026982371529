/* ModernSidebar.css */
:root {
    --sidebar-width: 280px;
    --glass-bg: #6366f1;
    --text-primary: rgba(255, 255, 255, 0.9);
    --text-active: #ffffff;
    --blur-intensity: blur(16px);
    --accent-color: #6366f1;
    --transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.modern-sidebar {
    width: var(--sidebar-width);
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background: #6366f1;
    backdrop-filter: var(--blur-intensity);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    padding: 1.5rem;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.sidebar-header {
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 1.5rem;
}

.logo {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--text-active);
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.sidebar-nav {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.nav-item {
    color: var(--text-primary)!important;
    text-decoration: none!important;
    border-radius: 12px;
    transition: var(--transition)!important;
    position: relative!important;
    overflow: hidden;
}

.nav-content {
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    position: relative;
    color: #ffffff;
}

.nav-icon {
    font-size: 1.25rem;
    width: 32px;
    display: flex;
    justify-content: center;
}

.active-indicator {
    position: absolute;
    right: -8px;
    width: 4px;
    height: 0%;
    background: var(--accent-color);
    border-radius: 2px;
    transition: var(--transition);
}

.nav-item.active {
    background-color: rgba(113, 116, 255, 0.817)!important;
    color: var(--text-active);
}

.nav-item.active .active-indicator {
    height: 60%;
}

.nav-item:hover:not(.active) {
    background: rgba(255, 255, 255, 0.05);
}

.logout-section {
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #fff;
    border-radius: 12px;
    cursor: pointer;
    transition: var(--transition);
    margin-top: auto;
}

.logout-section:hover {
    background: rgba(255, 255, 255, 0.05);
}

/* Mobile Styles */
@media (max-width: 768px) {
    .mobile-toggle {
        position: fixed;
        top: 0.5rem;
        left: 0.5rem;
        z-index: 1100;
        background: var(--glass-bg);
        backdrop-filter: var(--blur-intensity);
        border: 1px solid rgba(255, 255, 255, 0.1);
        color: white;
        padding: 0.75rem;
        border-radius: 12px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .sidebar-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(4px);
        z-index: 999;
    }
}

/* Desktop Styles */
@media (min-width: 769px) {
    .mobile-toggle {
        display: none;
    }

    .modern-sidebar {
        transform: translateX(0) !important;
    }
}