/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideInLeft {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideInRight {
    from {
        opacity: 0;
        transform: translateX(50px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.hero-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 4rem;
    padding: 4rem 2rem;
    /* background-image: linear-gradient(to right, #7F00FF, #E100FF); */
    background: linear-gradient(170deg, #3954ff 0, #617eff 50%, #c0e0ff 100%);
    height: 85vh;
    position: relative;
}

.hero-image-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 85vh;
    /* z-index: -1; */
}

.hero-image-bg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-content {
    max-width: 600px;
    animation: slideInLeft 0.8s ease-out;
    z-index: 10;
}

.hero-image {
    /* flex: 1; */
    animation: slideInRight 0.8s ease-out;
}

.hero-image img {
    width: 100%;
    max-width: 600px;
    border-radius: 12px;
    animation: slideUp 2s ease-in-out infinite;
}

@keyframes slideUp {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

.hero-title {
    font-size: 60px !important;
    /* margin-bottom: 1.5rem; */
    color: #fff;
    font-family: 'poppins';
    font-weight: 700;
}

.hero-sub-title{
    color: #fff;
    font-family: 'poppins';
    font-weight: 400;
    margin-bottom: 20px;
}

.hero-content .domain-list {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 2rem;
    /* flex-wrap: wrap; */
    /* justify-content: center; */
    animation: fadeIn 0.8s ease-out 0.2s backwards;
    font-family: 'poppins';
}

.hero-content .domain-list:nth-child(1){
    padding: 0 10px 0 0!important;
}

.domain-item {
    font-size: 1rem;
    color: #fff;
    padding: 0 10px;
    display: flex;
    gap: 15px;
    animation: fadeIn 0.8s ease-out backwards;
}

.domain-item span {
    color: #fff;
    position: relative;
}

.domain-item span::after {
    content: "|";
    position: absolute;
    right: -20px;
    color: #fff;

}

.hero-content .search-container {
    max-width: 600px;
    width: 100%;
    animation: fadeIn 0.8s ease-out 0.4s backwards;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 15px;
}

.hero-content .search-form {
    display: flex;
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;
}


.hero-content .search-input {
    padding: 0.8rem !important;
    font-size: 1rem;
    min-width: 450px;
    transition: all 0.3s ease;
    outline: none;
    width: 50%;
    border-radius: 0%;
}

.hero-content .search-select {
    padding: 0.8rem!important;
    background: white;
    font-size: 1rem;
    transition: all 0.3s ease;
    width: 90px;
}

.hero-content .search-select:focus {
    outline: none;
    border-color: #0984e3;
}

.hero-content .search-button {
    padding: 0.8rem 1.5rem;
    background: #0984e3;
    color: white;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.search-button:hover {
    background: #0873c4;
    transform: translateY(-2px);
}

@media (max-width: 992px) {
    .hero-container {
        flex-direction: column;
    }

    .hero-title {
        font-size: 50px !important;
    }
}

@media (max-width: 768px) {
    .hero-container {
        min-height: 100vh;
    }

    .search-form {
        flex-direction: column;
        gap: 1rem;
    }

    .search-input {
        min-width: 335px!important;
    }

    .domain-list {
        flex-wrap: wrap;
    }

    .domain-item {
        gap: 10px;
    }

    .hero-title {
        font-size: 35px !important;
    }
}