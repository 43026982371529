.admin-container {
    display: grid;
    grid-template-columns: 250px 1fr;
}

/* Main Content Area */
.main-content {
    width: 100%;
    padding: 30px 30px 30px 290px;
    /* margin-left: 280px; */
}


@media (max-width: 768px) {
    .main-content {
        padding: 60px 30px;
    }
}