/* DomainListPage.css */
:root {
    --sidebar-width: 280px;
    --glass-bg: rgba(255, 255, 255, 0.9);
    --blur-intensity: blur(10px);
    --accent-color: #6366f1;
    --text-primary: #1e293b;
}

.admin-content {
    margin-left: var(--sidebar-width);
    padding: 2rem;
    min-height: 100vh;
}

.domain-list-container {
    padding: 2rem;
    min-height: 100vh;
    background: var(--glass-bg);
    backdrop-filter: var(--blur-intensity);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin-bottom: 2rem;
    padding: 1.5rem;
    background: var(--glass-bg);
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.header h1 {
    font-size: 1.8rem;
    color: var(--text-primary);
    margin: 0;
    font-weight: 600;
}

.filters {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    margin-bottom: 2rem;
    padding: 1.5rem;
    background: var(--glass-bg);
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.filters input,
.filters select {
    padding: 0.875rem 1rem!important;
    border: 1px solid #e2e8f0;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.9);
    transition: all 0.2s ease;
}

.filters input:focus,
.filters select:focus {
    outline: none;
    border-color: var(--accent-color);
    box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.1);
}

.domains-table-wrapper {
    overflow-x: auto;
    background: var(--glass-bg);
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.domains-table {
    width: 100%;
    border-collapse: collapse;
    min-width: 800px;
}

.domains-table th {
    padding: 1.25rem;
    background: #f8fafc;
    color: #64748b;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.85rem;
    letter-spacing: 0.5px;
    text-align: justify;
}

.domains-table td {
    padding: 1.25rem;
    border-bottom: 1px solid #f1f5f9;
    color: var(--text-primary);
}

.btn-primary {
    background: linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%);
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 12px;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    transition: transform 0.2s ease;
    width: 215px;
}

.btn-primary:hover {
    transform: translateY(-1px);
}

.action-buttons {
    display: flex;
    gap: 0.5rem;
}

.btn-edit {
    background: rgba(101, 163, 13, 0.1);
    color: #65a30d;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    transition: all 0.2s ease;
}

.btn-delete {
    background: rgba(239, 68, 68, 0.1);
    color: #ef4444;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    transition: all 0.2s ease;
}

.pagination {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    margin-top: 2rem;
}

.pagination button {
    padding: 0.5rem 1rem;
    border: 1px solid #e2e8f0;
    background: var(--glass-bg);
    border-radius: 8px;
    transition: all 0.2s ease;
}

.pagination button.active {
    background: linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%);
    color: white;
    border-color: transparent;
}

@media (max-width: 768px) {
    .domain-list-container {
        padding: 1rem;
    }

    .header {
        padding: 1rem;
    }

    .filters {
        grid-template-columns: 1fr;
        padding: 1rem;
    }

    .domains-table th,
    .domains-table td {
        padding: 1rem;
    }
}

@media (max-width: 480px) {
    .domains-table {
        min-width: 100%;
    }

    .domains-table td:last-child {
        position: sticky;
        right: 0;
        background: white;
        box-shadow: -2px 0 8px rgba(0, 0, 0, 0.05);
    }

    .btn-primary {
        width: 100%;
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .admin-content {
        margin-left: 0;
        padding: 1rem;
    }
}