/* DomainSearch.css */
:root {
    --primary: #2563eb;
    --secondary: #4f46e5;
    --accent: #f59e0b;
    --light: #f8fafc;
    --dark: #1e293b;
}

.search-results-page {
    background: var(--light);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.results-container {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 0 1.5rem;
    flex: 1;
}

h2 {
    color: var(--dark);
    font-size: 2rem;
    margin-bottom: 2rem;
    font-weight: 700;
    position: relative;
    padding-left: 1rem;
}

.results-container h2::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 80%;
    background: var(--primary);
    border-radius: 4px;
}

/* Exact Match Section */
.exact-match-section {
    margin-bottom: 3rem;
    position: relative;
}

.highlighted {
    background: linear-gradient(135deg, #ffffff 0%, #f8fafc 100%);
    border-radius: 16px;
    padding: 2rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    border: 1px solid #e2e8f0;
    transition: transform 0.2s ease;
    max-width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.highlighted:hover {
    transform: translateY(-2px);
}

.domain-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1.5rem;

}

.domain-header h3 {
    font-size: 25px;
    color: var(--dark);
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
    text-align: left;
    font-weight: 600;
    height: 62px;
    flex-wrap: wrap;
    justify-content: center;
}

.tld {
    color: var(--primary);
    font-weight: 600;
    font-size: 1.25rem;
}

.domain-price {
    font-size: 20px!important;
    color: var(--primary);
    font-weight: 700;
    background: rgba(37, 99, 235, 0.1);
    padding: 0.1rem 1.5rem;
    border-radius: 5px;
}

.domain-body{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.category {
    color: #64748b;
    font-size: 0.9rem;
    position: relative;
    /* padding-left: 1.5rem; */
}

/* .category::before {
    content: '•';
    color: var(--accent);
    position: absolute;
    left: 0;
} */

.exactMatch_btn_div{
    display: flex;
    gap: 20px;

}

/* Buttons */
.buy-button {
    background-color: rgb(14, 171, 14);
    /* background: linear-gradient(170deg, #3954ff 0, #617eff 50%, #c0e0ff 100%); */
    color: white;
    padding: 10px 1rem;
    border-radius: 8px;
    font-weight: 600;
    transition: all 0.3s ease;
    border: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
}

.buy-button:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 6px -1px rgba(37, 99, 235, 0.2);
}

.makeOffer-button {
    background-color: rgb(232, 129, 10);
    /* background: linear-gradient(135deg, var(--primary), var(--secondary)); */
    color: white;
    padding: 10px 1rem;
    border-radius: 8px;
    font-weight: 600;
    transition: all 0.3s ease;
    border: none;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.makeOffer-button:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 6px -1px rgba(37, 99, 235, 0.2);
}


.backToSearch_btn {
    /* background-color: rgb(232, 129, 10); */
    background: linear-gradient(170deg, #3954ff 0, #617eff 78%, #c0e0ff 100%);
    color: white;
    padding: 10px 2rem;
    /* border-radius: 8px; */
    font-weight: 600;
    transition: all 0.3s ease;
    border: none;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin: 40px 0 20px 0;
    width: fit-content;
}

.backToSearch_btn:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 6px -1px rgba(37, 99, 235, 0.2);
}

/* Related Domains Section */
.related-domains-section {
    margin-top: 3rem;
}

.related-domains-section .domain-grid {
    display: flex;
    justify-content: left;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 20px;
    /* width: 250px; */
}

.domain-card {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease;
    border: 1px solid #f1f5f9;
    width: 270px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.domain-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.05);
}

.view-details {
    background: var(--dark);
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    font-weight: 500;
    transition: all 0.2s ease;
}

.view-details:hover {
    background: #334155;
    transform: translateY(-1px);
}

/* Loader Animation */
.loader {
    display: inline-block;
    padding: 1.5rem 2rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
}

.loader:after {
    content: " ";
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
    border-radius: 50%;
    border: 2px solid var(--dark);
    border-color: var(--dark) transparent var(--dark) transparent;
    animation: loader 1.2s linear infinite;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.offer-modal {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.offer-modal h3 {
    color: var(--dark);
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
}

.offer-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    margin: 0 0 10px;
}

.form-group label {
    color: var(--dark);
    font-size: 0.9rem;
    margin: 0;
}

.form-group input {
    padding: 0.75rem!important;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    font-size: 1rem;
}

.modal-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    .results-container {
        padding: 0 1rem;
    }

    .domain-header {
        flex-direction: column;
        gap: 1rem;
        margin: 0;
    }

    .domain-header h3{
        font-size: 25px;
        height: 47px;
    }

    .domain-grid {
        grid-template-columns: 1fr;
    }
    .highlighted{
        max-width: 100%;
    }

    .domain-card{
        width: 340px;
    }

    .form-group {
        margin: 0 0 8px;
    }

    .related-domains-section .domain-grid {
        justify-content: center;
    }
    .form-group input {
        padding: 0 10px!important;
        border-radius: 0px;
    }

    .offer-modal {
        padding: 5px;
    }
    .offer-modal h3{
        font-size: 16px;
        padding: 0.5rem;
        margin-bottom: 10px;
    }
    .offer-modal p{
        font-size: 14px!important;
    }
}