/* AboutPage.css */

/* General Styles */
body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
}

.about-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
}

/* Hero Section with Background Image and Overlay */
.about-hero {
  width: 100%;
  height: 500px;
  background-image: url('../../../public/inner-banner-bg.jpg')!important;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(170deg, #3954ff 0, #617eff 50%, #c0e0ff 100%);
  opacity: 0.7;
}

.about-page .about-hero .hero-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px!important;
  width: 100%;
  padding: 0 2rem;
  position: relative;
  z-index: 1;
  color: #fff;
}

.about-page .about-hero .hero-text {
  max-width: 600px;
}

.about-page .about-hero .hero-text h1 {
  font-size: 3rem;
  margin: 0;
  font-weight: 700;
  letter-spacing: -1px;
}

.hero-text p {
  font-size: 1.2rem;
  margin-top: 1rem;
  color: #e0e0e0;
}

.hero-image img {
  max-width: 400px;
  height: auto;
  border-radius: 12px;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); */
}

/* Content Sections */
.about-content {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.about-section {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-section:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.about-section.mission {
  flex-direction: row;
}

.about-section.team {
  flex-direction: row-reverse;
}

.about-section.why-choose-us {
  flex-direction: row;
}

.section-image {
  flex: 1;
}

.section-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section-text {
  flex: 1;
  padding: 2rem;
}

.section-text h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 1rem;
  font-weight: 600;
}

.section-text p {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.6;
}

.section-text ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
}

.section-text ul li {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;
}

.section-text ul li::before {
  content: '✔';
  color: #28a745;
  position: absolute;
  left: 0;
}

/* Call-to-Action Section */
.about-cta {
  width: 100%;
  text-align: center;
  padding: 4rem 2rem;
  background: linear-gradient(170deg, #3954ff 0, #617eff 50%, #c0e0ff 100%);
  color: #fff;
}

.about-cta h2 {
  font-size: 2rem;
  margin: 0;
  font-weight: 700;
}

.about-cta .cta-button {
  background-color: #fff;
  color: #3954ff;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-weight: 600;
  margin-top: 1rem;
}

.about-cta .cta-button:hover {
  background-color: #f1f1f1;
  transform: translateY(-3px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-content {
      flex-direction: column;
      text-align: center;
  }

  .hero-text h1 {
      font-size: 2rem;
  }

  .hero-image img {
      max-width: 100%;
      margin-top: 1rem;
  }

  .about-section {
      flex-direction: column;
  }

  .about-section.mission,
  .about-section.team,
  .about-section.why-choose-us {
      flex-direction: column;
  }

  .section-text {
      padding: 1rem;
  }

  .section-text h2 {
      font-size: 1.5rem;
  }

  .about-cta h2 {
      font-size: 1.5rem;
  }
}