.faq-section {
    background: url('../../../public/faq-bg.jpg') no-repeat center center/cover;
    color: #fff;
    padding: 100px 20px;
    text-align: center;
    position: relative;
    height: 800px;
}

.overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(170deg, #3954ff 0, #617eff 50%, #c0e0ff 100%);
    opacity: 0.8;
    /* z-index: -1; */
}

.faq-container {
    max-width: 1000px;
    margin: auto;
    position: relative;
    z-index: 10;
}

.faq-title {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #fff;
}

p {
    font-size: 18px;
    margin-bottom: 20px;
}

.faq-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.faq-side-image {
    max-width: 400px;
    height: auto;
    animation: slideUp 2s ease-in-out infinite;
}

@keyframes slideUp {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

.faq-list {
    flex: 1;
    margin-left: 20px;
}

.faq-item {
    background: #fff;
    color: #333;
    margin-bottom: 10px;
    overflow: hidden;
    transition: 0.3s;
}

.faq-question {
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.faq-answer {
    background: #f0f0f0;
    color: #333;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.5s ease-out, opacity 0.3s ease-out, padding 0.3s ease-out;
    padding: 0 15px;
}

.faq-item.open .faq-answer {
    max-height: 200px; /* Adjust if needed */
    opacity: 1;
    padding: 15px;
}

.faq-toggle {
    font-size: 20px;
    font-weight: bold;
}

@media (max-width: 768px) {
    .faq-content {
        flex-direction: column;
        align-items: center;
    }

    .faq-side-image {
        max-width: 100%;
        margin-bottom: 20px;
    }
    .faq-section {
        height: auto;
    }
}