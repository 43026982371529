.login-page-container {
    position: relative;
    background: linear-gradient(to bottom, #002147, #0083ff);
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    z-index: 10;
    position: relative;
}

.login-box {
    background: white;
    padding: 40px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 350px;
}

.login-title {
    font-size: 24px;
    font-weight: bold;
}

.login-subtitle {
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
}

.login-form {
    display: flex;
    flex-direction: column;
}

.login-form label {
    text-align: left;
    font-size: 14px;
    margin-top: 10px;
}

.login-form input {
    width: 100%;
    padding: 10px !important;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 0;
    outline: none;
}

.options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    font-size: 12px;
}

.forgot-password {
    color: #7F00FF;
    text-decoration: none;
}

.login-btn {
    background: linear-gradient(170deg, #3954ff 0, #617eff 50%, #c0e0ff 100%);
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.separator {
    margin: 20px 0;
    font-size: 14px;
    color: #666;
}

.google-login {
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.register-link {
    margin-top: 10px;
    font-size: 14px;
}

.register-link a {
    color: orange;
    text-decoration: none;
}

.bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* z-index: -1; */
    opacity: 0.4;
}

.bg-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}