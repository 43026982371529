/* DomainForm.css */
:root {
    --sidebar-width: 280px;
    --glass-bg: rgba(255, 255, 255, 0.9);
    --blur-intensity: blur(10px);
    --accent-color: #6366f1;
    --text-primary: #1e293b;
}

.form-container {
    margin-left: var(--sidebar-width);
    min-height: 100vh;
    padding: 2rem;
    background: rgba(249, 250, 251, 0.8);
    backdrop-filter: var(--blur-intensity);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: margin 0.3s ease;
}

.form-content {
    background: var(--glass-bg);
    padding: 2.5rem;
    border-radius: 24px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 640px;
    transition: transform 0.3s ease;
}

.form-content:hover {
    transform: translateY(-2px);
}

.form-title {
    color: var(--text-primary);
    font-size: 2rem;
    margin-bottom: 2rem;
    font-weight: 700;
    position: relative;
    padding: 0 0 1rem 0;
}

.form-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 3px;
    background: var(--accent-color);
    border-radius: 2px;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-label {
    display: block;
    margin-bottom: 0.8rem;
    color: #64748b;
    font-size: 0.95rem;
    font-weight: 500;
}

.domain-input {
    display: flex;
    gap: 1rem;
    position: relative;
    align-items: center;
}

.domain-input::after {
    content: "•";
    position: absolute;
    left: calc(100% - 135px);
    color: #cbd5e0;
    font-size: 1.4rem;
}

.domain-input input,
.domain-input select {
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 12px;
    font-size: 1rem;
    transition: all 0.2s ease;
    background: rgba(255, 255, 255, 0.9);
}

.domain-input input {
    flex: 1;
}

.domain-input select {
    width: 140px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23718096' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 16px;
    border-radius: 0%;
}

.form-content input[type="text"],
.form-content input[type="number"],
.form-content input[type="date"],
.form-content textarea,
select {
    width: 100%;
    padding: 10px!important;
    border: 1px solid #e2e8f0;
    border-radius: 0;
    font-size: 1rem;
    transition: all 0.2s ease;
    background: rgba(255, 255, 255, 0.9);
}

.form-content textarea {
    min-height: 120px;
    resize: vertical;
    line-height: 1.6;
}

.form-content .btn-primary {
    background: linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%);
    color: white;
    padding: 10px 2rem;
    border: none;
    border-radius: 0!important;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.form-content .btn-primary:hover {
    opacity: 0.95;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(99, 102, 241, 0.2);
}

/* Focus States */
input:focus,
select:focus,
textarea:focus {
    border-color: var(--accent-color);
    outline: none;
    box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.1);
}

.quill {
    margin-bottom: 40px;
}

.ql-toolbar {
    border-radius: 4px 4px 0 0;
    border-color: #ddd !important;
}

.ql-container {
    border-radius: 0 0 4px 4px;
    border-color: #ddd !important;
    min-height: 200px;
}

.ql-editor {
    min-height: 200px;
    font-size: 16px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .form-container {
        margin-left: 0;
        padding: 1.5rem;
    }

    .form-content {
        padding: 1.5rem;
        border-radius: 16px;
    }

    .form-title {
        font-size: 1.75rem;
    }

    .domain-input {
        flex-direction: column;
    }

    .domain-input::after {
        display: none;
    }

    .domain-input select {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .form-title {
        font-size: 1.5rem;
    }

    .form-content .btn-primary {
        padding: 1rem;
    }
}