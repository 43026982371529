.register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    padding: 20px;
}

.register-card {
    background: rgba(255, 255, 255, 0.95);
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 450px;
    transition: transform 0.3s ease;
}

.register-card:hover {
    transform: translateY(-5px);
}

.register-title {
    text-align: center;
    color: #2d3748;
    margin-bottom: 30px;
    font-size: 2rem;
}

.register-form {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.input-group {
    position: relative;
}

.input-icon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #718096;
    font-size: 1.2rem;
}

.input-group input {
    width: 100%;
    padding: 15px 15px 15px 45px;
    border: 2px solid #e2e8f0;
    border-radius: 10px;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.input-group input:focus {
    outline: none;
    border-color: #667eea;
    box-shadow: 0 0 0 3px rgba(102, 126, 234, 0.2);
}

.input-group.error input {
    border-color: #fc8181;
}

.error-message {
    color: #fc8181;
    font-size: 0.875rem;
    margin-top: 5px;
    display: block;
}

.register-button {
    background: #667eea;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.register-button:hover {
    background: #764ba2;
}

.register-button:disabled {
    background: #cbd5e0;
    cursor: not-allowed;
}

.login-link {
    text-align: center;
    margin-top: 20px;
    color: #4a5568;
}

.login-link a {
    color: #667eea;
    text-decoration: none;
    font-weight: 600;
}

.login-link a:hover {
    text-decoration: underline;
}

@media (max-width: 480px) {
    .register-card {
        padding: 25px;
    }

    .register-title {
        font-size: 1.75rem;
    }
}